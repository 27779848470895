export const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement("script");
    scriptTag.src = src;
    console.log("scriptTag ", scriptTag);
    document.body.appendChild(scriptTag);
    resolve();
  });
};
export const cacheImages = async (srcArray) => {
  const promises = srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
    });
  });
  await Promise.all(promises);
};

// export const emptyCache = async (version) => {
//   if ("caches" in window) {
//     let preVersion = localStorage.getItem("version");
//     preVersion = preVersion ? parseInt(preVersion) : 0;
//     const cacheKeys = await caches.keys();
//     if (preVersion !== version && cacheKeys.length) {
//       for (let name of cacheKeys) {
//         caches.delete(name);
//       }
//       localStorage.setItem("version", version.toString());
//       window.location.reload(true);
//     }
//   }
// };
