import "../components/footer.scss";
import ono from "../assets/images/common/ono.svg";
import { Link, NavLink } from "react-router-dom";
import fb from "../assets/images/common/facebook.svg";
import "../modules/contact_us.scss";
import li from "../assets/images/common/li.svg";
import insta from "../assets/images/common/insta.svg";
import tw from "../assets/images/common/tw.svg";
const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="footer_main">
            <div className="row">
              <div className="col-lg-4 pl-0">
                <img src={ono} alt="ono-logo" className="mb-3" />
                <p className="body_para collor_black">
                  On a mission to empower farmers and farmer ecosystem players
                  to have an inclusive access to economic growth opportunities.
                </p>
                <div className="d-none d-sm-none d-md-block">
                  <div className="follow">
                    <h6 className="sub_para collor_black">FOLLOW US</h6>
                    <div className="d-flex social_media">
                      <a
                        href="https://www.facebook.com/OnoArk/"
                        target="_blank"
                      >
                        {" "}
                        <img src={fb} alt="icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/onoark/"
                        target="_blank"
                      >
                        {" "}
                        <img src={li} alt="icon" />
                      </a>
                      <a href="https://twitter.com/OnoArk" target="_blank">
                        {" "}
                        <img src={tw} alt="icon" />
                      </a>
                      <a
                        href="https://www.instagram.com/ono.ark/"
                        target="_blank"
                      >
                        {" "}
                        <img src={insta} alt="icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-3 col-xs-12">
                    <ul className="links_list">
                      <h5 className="body_para collor_black">ONO AG</h5>
                      <li>
                        {" "}
                        <a className="collor_black" href="/">
                          Home
                        </a>
                      </li>
                      <li>
                        <a className="collor_black" href="/company">
                          About ONO
                        </a>
                      </li>

                      <li>
                        {" "}
                        <a className="collor_black" href="/careers">
                          Careers
                        </a>
                      </li>
                      <li>
                        <a className="collor_black" href="/contact-us">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-5 col-xs-12">
                    <ul className="links_list">
                      <h5 className="body_para collor_black upper_leter">
                        Our Products
                      </h5>
                      <li>
                        <a className="collor_black" href="/connect">
                          <h5 className="body_para collor_black mb-0">
                            ONO CONNECT{" "}
                          </h5>
                          <p>CONNECT | DISCOVER | DECIDE</p>
                        </a>
                      </li>
                      <li>
                        <a className="collor_black" href="/click">
                          <h5 className="body_para collor_black mb-0">
                            ONO CLICK
                          </h5>
                          <p>SIMPLE | SAFE | SECURE</p>
                        </a>
                      </li>
                      <li>
                        <a className="collor_black" href="/mandi">
                          <h5 className="body_para collor_black mb-0">
                            ONO MANDI
                          </h5>
                          <p>SELL | BUY | GROW</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <ul className="links_list links_list1">
                      <h5 className="body_para collor_black hidden_text">
                        Our Products
                      </h5>
                      <li>
                        <a className="collor_black" href="/cash">
                          <h5 className="body_para collor_black mb-0">
                            ONO CASH
                          </h5>
                          <p>QUICK | EASY | AFFORDABLE</p>
                        </a>
                      </li>
                      <li>
                        <h5 className="body_para collor_black mb-0">
                          ONO GAADI
                        </h5>
                        <p>Comming Soon...</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-sm-block d-md-none">
              <div className="follow">
                <h6 className="sub_para collor_black">FOLLOW US</h6>
                <div className="d-flex social_media">
                  <a href="https://www.facebook.com/OnoArk/" target="_blank">
                    {" "}
                    <img src={fb} alt="icon" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/onoark/"
                    target="_blank"
                  >
                    {" "}
                    <img src={li} alt="icon" />
                  </a>
                  <a href="https://twitter.com/OnoArk" target="_blank">
                    {" "}
                    <img src={tw} alt="icon" />
                  </a>
                  <a href="https://www.instagram.com/ono.ark/" target="_blank">
                    {" "}
                    <img src={insta} alt="icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="office_locations">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xs-12 pl-0">
              <h6>REGISTERED OFFICE</h6>
              <p>
                ONO ARK INDIA PVT. LTD.<br></br> L-148, 5th Main Road, Sector 6,
                HSR Layout,<br></br> Bengaluru, Karnataka, 560102
                <br></br>
                CIN: U72900KA2021PTC148332
              </p>
            </div>
            <div className="col-lg-4 col-xs-12">
              <h6 className="upper_leter">Corporate OFFICE</h6>
              <p>
                ONO ARK INDIA PVT. LTD.<br></br> B-Block, BHIVE Workspace - No.
                112, AKR Tech Park, 7th Mile Hosur Rd, Bengaluru, Karnataka
                560068
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="privacy_links">
              <a href="/privacy-policy">Privacy Policy </a>
              <span>| </span>
              <a href="/conditions-of-use">Conditions of Use</a>
            </div>
            <div>
              <p>Copyright © 2024 ONO ARK INDIA PVT. LTD.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
