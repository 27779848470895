import React, { useState } from "react";
import ono from "../assets/images/common/ono.svg";
import c from "../assets/images/common/close.svg";
import "../assets/css/style.scss";
import * as $ from "jquery";
import web from "../assets/images/common/web.svg";
import click_s from "../assets/images/common/click_s.svg";
import connect_s from "../assets/images/common/connect_s.svg";
import mandi_s from "../assets/images/common/mandi_s.svg";
import cash_s from "../assets/images/common/cash_s.svg";
import p_s from "../assets/images/common/p_s.svg";
import dropdown_img from "../assets/images/common/dropdown_img.svg";
import menu from "../assets/images/common/menu.svg";
import connect_img from "../assets/images/products/connect_img.png";
import click_img from "../assets/images/products/click_img.png";
import cash_img from "../assets/images/products/cash_img.png";
import mandi_img from "../assets/images/products/mandi_img.png";
function Headers() {
  function tooggle() {
    console.log("click");
    $("#navbarSupportedContent").addClass("menu");
    $(".dropdown-menu").css("display", "none");
  }
  function close() {
    console.log("click");
    $("#navbarSupportedContent").removeClass("menu");
  }
  function linkEvent() {
    console.log("click");
    $("#navbarSupportedContent").removeClass("menu");
  }
  const [imageSrc, setImageSrc] = useState(dropdown_img);
  if ($(window).width() > 991) {
    $("body").on("mouseenter mouseleave", ".dropdown-hover", function (e) {
      let dropdown = $(e.target).closest(".dropdown-hover");
      dropdown.addClass("show");
      $(".dropdown-menu").css("display", "block");
      setTimeout(function () {
        dropdown[dropdown.is(":hover") ? "addClass" : "removeClass"]("show");
        $(".dropdown-item").click(function () {
          $(".dropdown-menu").removeClass("show");
          $(".dropdown-menu").css("display", "none");
        });
      }, 300);
    });
  }
  const productsClick = (e) => {
    let dropdown = $(e.target).closest(".dropdown-hover");
    dropdown.addClass("show");
    console.log(e, dropdown, "productsClick");
    $(".dropdown-menu").css("display", "block");
  };
  return (
    <header>
      <div>
        <div className="p-2 news_announcement">
          <a
            href="https://economictimes.indiatimes.com/small-biz/sme-sector/agri-tech-company-ono-raises-rs-11-crore-in-seed-funding-round-to-empower-supply-chain/articleshow/109004868.cms"
            target="_blank"
          >
            ONO raises Rs. 11 Crore in Seed Funding! Click to Learn More. 🎉
          </a>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
          <div class="container">
            <a
              class="navbar-brand"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img src={ono} alt="logo" />
            </a>
            <div class="navbar-toggler1" onClick={() => tooggle()}>
              <img src={menu} />
            </div>
            <div class="menu-sec" id="navbarSupportedContent">
              <div>
                <div className="closebtn_sec">
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={ono} alt="logo" className="logo" />
                    <a
                      href="javascript:void(0)"
                      class="closebtn"
                      onClick={() => close()}
                    >
                      <img src={c} alt="close" />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex "> */}
              <ul class="navbar-nav">
                <li class="nav-item dropdown dropdown-slide dropdown-hover">
                  <div className="nav-link" onClick={(e) => productsClick(e)}>
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      PRODUCTS{" "}
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <div className="row">
                      <div className="col-lg-4 pr-0">
                        <a
                          class="dropdown-item connect_d"
                          onMouseEnter={() => setImageSrc(connect_img)}
                          onMouseLeave={() => setImageSrc(dropdown_img)}
                          href="/connect"
                        >
                          <img src={connect_s} alt="icon" />
                          <div>
                            <h6>ONO CONNECT </h6>
                            <p>
                              Crop Price and Mandi discovery platform for
                              farmers.
                            </p>
                          </div>
                        </a>
                        <a
                          class="dropdown-item"
                          onMouseEnter={() => setImageSrc(click_img)}
                          onMouseLeave={() => setImageSrc(dropdown_img)}
                          href="/click"
                        >
                          <img src={click_s} alt="icon" />
                          <div>
                            <h6>ONO CLICK </h6>
                            <p>
                              Mandi SaaS platform for commission agents and
                              traders.
                            </p>
                          </div>
                        </a>
                        <a
                          class="dropdown-item"
                          onMouseEnter={() => setImageSrc(mandi_img)}
                          onMouseLeave={() => setImageSrc(dropdown_img)}
                          href="/mandi"
                        >
                          <img src={mandi_s} alt="icon" />
                          <div>
                            <h6>ONO MANDI </h6>
                            <p>
                              A mid-mile trading platform for traders and
                              Commission Agents.
                            </p>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-4">
                        <a
                          class="dropdown-item"
                          onMouseEnter={() => setImageSrc(cash_img)}
                          onMouseLeave={() => setImageSrc(dropdown_img)}
                          href="/cash"
                        >
                          <img src={cash_s} alt="icon" />
                          <div>
                            <h6>ONO CASH </h6>
                            <p>
                              Neo-Lending platform for farmers, commission
                              agents, and traders.
                            </p>
                          </div>
                        </a>
                        <a class="dropdown-item last_item" href="/all-products">
                          <img src={p_s} alt="icon" />
                          <div>
                            <h6>SEE ALL PRODUCTS</h6>
                            <p>
                              Explore our diverse range of offerings for your
                              every need.
                            </p>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-4">
                        <div className="right_col_drop">
                          <img src={imageSrc} alt="image" />
                          {/* <button className="secondry_btn d-flex mx-auto">
                          See All Products
                        </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="nav-item" onClick={() => linkEvent()}>
                  <a class="nav-link" href="/company">
                    COMPANY
                  </a>
                </li>
                <li class="nav-item" onClick={() => linkEvent()}>
                  <a class="nav-link" href="/careers">
                    CAREERS
                  </a>
                </li>
                <li class="nav-item" onClick={() => linkEvent()}>
                  <a class="nav-link" href="/contact-us">
                    CONTACT US
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav navbar-nav1">
                <li class="nav-item">
                  <a class="nav-link d-flex" to="/Resources">
                    <img src={web} alt="image" />
                    <p className="ml-2">ENGLISH</p>
                    <div class="gtranslate_wrapper"></div>
                  </a>
                </li>
              </ul>
              {/* </div> */}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
export default Headers;
