import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import "./home.scss";
import OurProduct from "./our-product";
import OurPresence from "./pur-presence";
import OurTeams from "./our-teams";
import CalltoAction from "../../components/call_to_action";
import bg1 from "../../assets/images/banner/bg1.png";
import bg2 from "../../assets/images/banner/bg2.png";
import bg3 from "../../assets/images/banner/bg3.png";
const bnr_slider = [
  {
    header: "Creating the Conditions for Agriculture Supply Chain",
    para: "On a mission to empower farmers and farmer ecosystem players to have an inclusive access to economic growth opportunities.",
    action_text: "TRANSPARENT | TRUSTED | RELIABLE",
    main_img: bg1,
    span_text: "Disruptions",
    link: "/company",
  },

  {
    header: "Democratising Price and Mandi",
    para: "Promoting transparency throughout the agriculture supply chain to increase trust. Discover crop price across mandis at your fingertips.",
    action_text: "TRANSPARENT | TRUSTED | RELIABLE",
    main_img: bg2,
    span_text: "Intelligence",
    link: "/connect",
  },
  {
    header: "A First-of-its-Kind Multisided",
    para: "For Farmers, Commission Agents, Traders, Buyers, Transporters, and Lenders to make data-driven decisions and improve supply chain efficiencies.",
    action_text: "TRANSPARENT | TRUSTED | RELIABLE",
    main_img: bg3,
    span_text: "Agritech Platform",
    link: "/all-products",
  },
];
const Home = () => {
  // var home_bg = {
  //   backgroundImage: `url(${bg})`,
  // };
  useEffect(() => {
    //AOS.init();
  }, []);
  return (
    <div className="main_sec">
      {/* bannner */}
      <div className="banner">
        <OwlCarousel
          items={1}
          className="owl-theme banner_car"
          margin={20}
          loop
          dots={true}
          nav
          autoplay={true}
          autoplayTimeout={5000}
          autoplayHoverPause={true}
        >
          {bnr_slider.map((item, i) => {
            return (
              <div class="item">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-xs-12 p-0 green_bg">
                    <div className="container">
                      <div class="banner_head">
                        <h4
                          className="home_text"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          HOME
                        </h4>
                        <h1 data-aos="fade-up" data-aos-duration="1000">
                          {item.header}{" "}
                          <span className="special_text">{item.span_text}</span>
                        </h1>
                        <p
                          className="body_para_main"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          {item.para}
                        </p>
                        <h5 data-aos="fade-up" data-aos-duration="1000">
                          {item.action_text}
                        </h5>
                        <Link to={item.link}>
                          <button className="secondry_btn">Explore More</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 p-0">
                    {/* {imagesLoaded ? ( */}
                    <div className="banner_img_bg">
                      <img src={item.main_img} alt="image" className="" />
                    </div>
                    {/* ) : (
                      <SklRectangle />
                    )} */}
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>

      {/* products page */}
      <OurProduct />
      {/* location */}
      <OurPresence />
      {/* <div className="loc_customer">
        <div className="presence_sec common_sec_padding pt-0">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="border_line"></div>
              <h2 className="section_head1">Our Presence</h2>
              <div className="border_line"></div>
            </div>
            <div className="presence_row common_row" id="inview-example">
              <div className="row align-items-center">
                <div
                  className="col-lg-2 pl-0 numbers_coll"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div>
                    <h5
                      className="section_head counter-count timer"
                      data-count="25"
                    >
                      580+
                    </h5>
                    <p className="body_para upper_leter text_bold">
                      COMMISSION AGENTS/traders
                    </p>
                  </div>
                  <div>
                    <h5 className="section_head">73k+</h5>
                    <p className="body_para upper_leter text_bold">farmers</p>
                  </div>
                  <div>
                    <h5 className="section_head">218+</h5>
                    <p className="body_para upper_leter text_bold">MANDIS</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div id="map"></div>
                </div>
                <div
                  className="col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2 className="section_head mb-2">Impact in India</h2>
                  <p className="body_para">
                    Our multi-sided platform is driving the real impact across
                    the Agri ecosystem through transparency, supply and demand
                    visibility, price intelligence, market discovery and
                    real-time data access.
                  </p>
                  <div className="d-flex number_row">
                    <div>
                      <h5 className="section_head">304</h5>
                      <p className="body_para upper_leter text_bold">
                        DISTRICTS
                      </p>
                    </div>
                    <div className="ml-5">
                      <h5 className="section_head">13</h5>
                      <p className="body_para upper_leter text_bold">STATES</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="customers">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="border_line white_border"></div>
              <h2 className="section_head1 color_white">Success stories</h2>
              <div className="border_line white_border"></div>
            </div>
            <div className="stories_row common_row">
              <OwlCarousel
                items={1}
                className="owl-theme banner_car cust_car"
                margin={20}
                loop
                dots={false}
                nav
                // autoplay={true}
                autoplayTimeout={5000}
                autoplayHoverPause={true}
                autoHeight={true}
                // autoHeightClass="owl-height"
              >
                {cust_slider.map((item, i) => {
                  return (
                    <div className="item">
                      <div className="row">
                        <div className="col-lg-4">
                          <img
                            src={item.image}
                            className="customer"
                            alt="customer"
                          />
                        </div>
                        <div className="col-lg-8">
                          <div className="d-flex align-items-start">
                            <div>
                              <h2 className="section_head mr-3 color_white">
                                {item.header}
                              </h2>
                              <p className="body_para">{item.sub}</p>
                            </div>
                            <img src={stars} alt="stars" />
                          </div>
                          <p className="sub_para">{item.para}</p>
                          <img src={quote} alt="stars" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
            <div className="d-flex align-items-center common_row">
              <div className="border_line white_border"></div>
              <h2 className="section_head1 color_white"> More customers </h2>
              <div className="border_line white_border"></div>
            </div>
            <div className="market_names">
              <div className="row">
                {markets_slider.map((item, i) => {
                  return (
                    <div className="col-lg-2 p-0">
                      <div
                        className="card final"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000"
                      >
                        <h2 className="section_head color_white">
                          {item.head}
                        </h2>
                        <p className="body_para">{item.sub}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* team */}
      <OurTeams />
      <CalltoAction color={"#16A02C"} />
    </div>
  );
};
export default Home;
