import React, { useEffect, useState } from "react";
import { useInView } from "react-hook-inview";
import b_smile from "../../../assets/images/banner/teams/b_smile.svg";
import s_smile from "../../../assets/images/banner/teams/s_smile.svg";
import m_smile from "../../../assets/images/banner/teams/m_smile.svg";
import appu from "../../../assets/images/banner/teams/appu.svg";
import da from "../../../assets/images/banner/teams/da.svg";
import mu from "../../../assets/images/banner/teams/m.svg";
import ve from "../../../assets/images/banner/teams/ve.svg";
import ji from "../../../assets/images/banner/teams/ji.svg";
import si from "../../../assets/images/banner/teams/si.svg";
import ma from "../../../assets/images/banner/teams/ma.svg";
import lo from "../../../assets/images/banner/teams/lo.svg";
import team_mob from "../../../assets/images/common/team_mob.png";

const OurTeams = () => {
  const [ref, inView] = useInView();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded === false && inView) {
      setLoaded(true);
    }
  }, [inView]);
  return (
    <div ref={ref}>
      {!loaded ? (
        <></>
      ) : (
        <div className="team_sec common_sec_padding">
          <div className="container">
            <div className="d-flex align-items-center head_line">
              <div className="border_line"></div>
              <h2 className="section_head1">Our TEAM</h2>
              <div className="border_line"></div>
            </div>
            <h2 className="section_head collor_black">
              Help Create the Future of Agri-Ecosystem<br></br> Come Join Us!
            </h2>
            <div class="d-block d-sm-block d-md-none">
              <div className="team_mob common_row">
                <img src={team_mob} alt="image" />
              </div>
            </div>
            <div class="d-none d-sm-none d-md-block">
              <div className="common_row teams_row">
                <img
                  src={s_smile}
                  className="s_smile1"
                  data-aos="fade-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={m_smile}
                  className="m_smile"
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={si}
                  className="si"
                  alt="smile"
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                />
                <img
                  src={appu}
                  className="appu"
                  data-aos="fade-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={mu}
                  className="murali"
                  data-aos="fade-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={ji}
                  className="jitesh"
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img src={b_smile} alt="smmile" className="center_img" />
                <img
                  src={lo}
                  className="lokesh"
                  data-aos="fade-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={ma}
                  className="mahesh"
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={s_smile}
                  className="s_smile2"
                  data-aos="fade-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={ve}
                  className="venkat"
                  data-aos="fade-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
                <img
                  src={da}
                  className="david"
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  alt="smile"
                />
              </div>
            </div>
            <button className="primary_btn">
              <a href="/careers#team" className="color_white">
                MEET OUR TEAM
              </a>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default OurTeams;
