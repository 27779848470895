import { Link } from "react-router-dom";
import { useInView } from "react-hook-inview";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import p_cl from "../../../assets/images/products/click/1.png";
import p_con from "../../../assets/images/products/connect/1.png";
import p_ca from "../../../assets/images/products/cash/1.png";
import p_ma from "../../../assets/images/products/mandi/1.png";
import { useEffect, useState } from "react";
const products_slider = [
  {
    header: "ONO CONNECT",
    para: "Crop Price and Mandi discovery platform for farmers. It makes it possible to locate neighbouring APMCs / Mandis and offers real-time, accurate crop prices across Mandis.",
    action: "Learn More",
    img1: p_con,
    linkVal: "/connect",
  },
  {
    header: "ONO CLICK",
    para: "APMC / MANDI SaaS platform for Commission Agents and Traders that offers Price and Market Intelligence, Payments, Collections and digitization of APMC / MANDI Operations.",
    action: "Learn More",
    img1: p_cl,
    linkVal: "/click",
  },
  {
    header: "ONO CASH",
    para: "Neo-Lending Platform for Farmers, Commission Agents, and Traders. It offers timely access to formal finance through invoice discounting and working capital loans.",
    action: "Learn More",
    img1: p_ca,
    linkVal: "/cash",
  },
  {
    header: "ONO MANDI",
    para: "Platform for Mid-Mile Trading for Traders. It enables Supply and Demand Visibility, Price Intelligence and Market Discovery.",
    img1: p_ma,
    action: "Learn More",
    linkVal: "/mandi",
  },
];
const state2 = {
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 3,
    },
  },
};
const OurProduct = () => {
  const [ref, inView] = useInView();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded === false && inView) {
      setLoaded(true);
    }
  }, [inView]);
  return (
    <div ref={ref}>
      {!loaded ? (
        <></>
      ) : (
        <div className="products_sec common_sec_padding">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="border_line"></div>
              <h2 className="section_head1">Our PRODUCTS</h2>
              <div className="border_line"></div>
            </div>
            <div className="products_row">
              <OwlCarousel
                items={3}
                className="owl-theme products_car"
                margin={20}
                loop
                dots={false}
                nav
                center={true}
                responsive={state2.responsive}
              >
                {products_slider.map((item, i) => {
                  return (
                    <div class="item">
                      <div className="card">
                        <h4>{item.header} </h4>
                        <p className="body_para">{item.para}</p>
                        <Link to={item.linkVal}>
                          <h5>
                            <button className="secondry_btn">
                              {item.action}
                            </button>
                          </h5>
                        </Link>
                        <img src={item.img1} className="p_cl1" />
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
            <Link to="/all-products">
              <button
                className="primary_btn"
                // data-aos="fade-up"
                // data-aos-duration="1000"
              >
                SEE ALL PRODUCTS
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default OurProduct;
